/* eslint-disable react/prop-types */
import React, { useReducer, useEffect } from 'react'

import { CommonGAssets } from '@hhgtech/hhg-components/gAssets'
import { emotionCache, MantineProvider } from '@hhgtech/hhg-components/mantine'
import { MediaQueries, theme } from '@hhgtech/hhg-components/misc'
import { LOCALE } from '@hhgtech/hhg-components/types'
import { InSkinScript } from 'components/atoms/inskinScript'
import NotificationCustom from 'components/atoms/notificationCustom'
import { domainLocales } from 'domainLocales'
import { AppHooks } from 'hooks/useAppHooks'
import { useServerLogs } from 'hooks/useServerLogs'
import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Router from 'next/router'
import NProgress from 'nprogress' //nprogress module
import TagManager from 'react-gtm-module'
import { IntlProvider } from 'react-intl'
import { actionTypes } from 'state/actionTypes'
import { AppContext } from 'state/appContext'
import { appReducer } from 'state/appReducer'
import { initialState } from 'state/initialState'
import styled, { ThemeProvider } from 'styled-components'
import GlobalStyle from 'styles/globalStyle'
import GlobalStyleAmp from 'styles/globalStyleAmp'
import viMB from 'translations/mb/vi.json'
import vi from 'translations/vi.json'
// import en from 'translations/en.json'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '@hhgtech/hhg-components/style.css'
import '@hhgtech/hhg-components/lead.css'
import { CustomScripts } from '../components/templates/customScripts'
import { init } from '../sentry'

const LeadGen = dynamic(
  () => import('@hhgtech/hhg-components/lead').then((res) => res.LeadGen),
  {
    ssr: false,
  },
)
const FirebaseWebPush = dynamic(
  () =>
    import('../components/templates/firebaseWebPush').then(
      (mod) => mod.FirebaseWebPush,
    ),
  {
    ssr: false,
  },
)

init()
CommonGAssets.baseDomain = 'marrybaby.vn'

const messages = {
  // 'vi-VN': en,
  'vi-VN': { ...vi, ...viMB },
}

if (process.browser) {
  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual'
  }
}

const Alert = dynamic(() => {
  return import('@hhgtech/hhg-components/components').then((mod) => mod.Alert)
})

// declare let google: any
export default function HHGDiscover({ Component, pageProps, err }) {
  useServerLogs(pageProps)
  const { locale, defaultLocale } = useRouter()

  const router = useRouter()

  const messagesLocale =
    locale === 'hi-IN' && router?.query?.slug?.includes('en-diabetes')
      ? 'vi-VN'
      : locale

  const isAmp = useAmp()

  const { isMobileSsr } = pageProps
  const enhancedState = {
    ...initialState,
    isMobileSsr,
    newBookmark: [],
  }
  const [state, dispatch] = useReducer(appReducer, enhancedState)

  const notifications = state?.notifications || []

  useEffect(() => {
    NProgress.configure({ showSpinner: false })
    setTimeout(() => {
      Router.events.on('routeChangeStart', () => NProgress.start())
    }, 100)
    Router.events.on('routeChangeComplete', () => NProgress.done())
    Router.events.on('routeChangeError', () => NProgress.done())
  }, [])

  if (
    !isAmp &&
    typeof window !== 'undefined' &&
    !!process.env.NEXT_PUBLIC_GTM
  ) {
    if (
      !window.dataLayer ||
      window.dataLayer.length < 1 ||
      (window.dataLayer.length === 1 &&
        typeof window.dataLayer[0].userID !== 'undefined')
    ) {
      const tagManagerArgs = {
        gtmId: process.env.NEXT_PUBLIC_GTM,
      }
      TagManager.initialize(tagManagerArgs)
    }
  }

  const pushNotifications = (notification) => {
    const notificationId = Math.floor(Math.random() * 1000)
    dispatch({
      type: actionTypes.PUSH_NOTIFICATION,
      payload: { id: notificationId, ...notification },
    })

    setTimeout(
      () => {
        dispatch({
          type: actionTypes.CLEAR_NOTIFICATION,
          payload: { id: notificationId },
        })
      },
      notification.type === 'firebase' ? 60000 * 2 : 5000,
    )
  }

  return (
    <IntlProvider
      locale={locale || defaultLocale}
      messages={messages[messagesLocale]}
      defaultLocale="vi-VN"
    >
      <script
        id="gpt-init"
        async
        dangerouslySetInnerHTML={{
          __html: `
            window.googletag = window.googletag || { cmd: [] };
            googletag.cmd.push(function () {
              googletag.pubads().collapseEmptyDivs(true);
              googletag.pubads().enableLazyLoad({
                fetchMarginPercent: 300,
                renderMarginPercent: ${locale === LOCALE.Vietnam ? 50 : 30},
                mobileScaling: 2.0,
              });
              googletag.enableServices();
            });
          `,
        }}
      />
      <InSkinScript />
      <AppContext.Provider
        value={{
          state,
          dispatch,
          pushNotifications,
        }}
      >
        <MantineProvider
          locale={locale}
          template="mb"
          emotionCache={emotionCache}
        >
          <ThemeProvider
            theme={{
              ...theme,
              colors: {
                ...theme.colors,
                primaryBase: theme.mbColors.pink,
              },
              defaultColor: theme.mbColors.dark,
            }}
          >
            <AppHooks />
            <StyledGoogleOnetapContainer id="google-onetap-container" />
            {isAmp ? (
              <GlobalStyleAmp theme={{ locale }} />
            ) : (
              <GlobalStyle theme={{ locale }} />
            )}
            <LeadGen
              apiUrl={
                process.env.NEXT_PUBLIC_LEAD_API ||
                'https://lead.hellohealthgroup.com/'
              }
              apiSubotUrl={
                process.env.NEXT_PUBLIC_SUBOT_URL ||
                'https://subot.marrybaby.vn/'
              }
              siteDomain={domainLocales[locale]}
              siteId={10}
              locale={locale}
              userInfo={state?.userInfo}
            />
            <Component {...pageProps} err={err} />
            <StyledAlert>
              {notifications.map((noti, notiIndex) => {
                if (noti.type === 'firebase') {
                  return <NotificationCustom data={noti} key={notiIndex} />
                }

                return (
                  <Alert
                    key={notiIndex}
                    closeIcon={
                      <img
                        loading="lazy"
                        alt="close"
                        src={`/images/closeLight.svg`}
                      />
                    }
                    contentIcon={
                      <img
                        loading="lazy"
                        className="content-icon"
                        src={`/images/${noti.type}.svg`}
                        alt="content-icon"
                      />
                    }
                    text={noti.message}
                    type={noti.type}
                    className="alert-noti"
                  />
                )
              })}
            </StyledAlert>
            <CustomScripts />
            {state?.userInfo !== undefined && (
              <FirebaseWebPush isLogin={!!state?.userInfo} />
            )}
          </ThemeProvider>
        </MantineProvider>
      </AppContext.Provider>
    </IntlProvider>
  )
}

const StyledAlert = styled.div`
  position: fixed;
  z-index: 1000;

  ${MediaQueries.mbUp} {
    .alert-noti {
      overflow: hidden;
      width: 360px;
      height: 72px;
      margin-bottom: 16px;
    }

    .content-icon {
      width: 20px;
      height: 20px;
    }

    right: 136px;
    top: 16px;
  }

  ${MediaQueries.mbDown} {
    width: 100%;
    padding: 8px;
    .alert-noti {
      width: 100%;
      height: 72px;
      margin-bottom: 16px;
    }

    .content-icon {
      width: 17px;
      height: 17px;
    }

    right: 0;
    top: 0;
  }
`
const StyledGoogleOnetapContainer = styled.div`
  position: fixed;
  z-index: 1000;

  ${MediaQueries.mbUp} {
    top: 16px;
    right: 0;
  }

  ${MediaQueries.mbDown} {
    bottom: 0;
  }
`
